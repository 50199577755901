.faq-container {
    max-width: 80vw;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
  }
  
  .faq-list {
    margin-top: 20px;
  }
  
  .faq-item {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:hover {
    background-color: #e0e0e0;
  }
  
  .faq-item.active {
    background-color: #d3e8fd;
  }
  
  .faq-question {
    font-weight: bold;
  }
  
  .faq-answer {
    display: none;
    margin-top: 10px;
  }
  
  .faq-item.active .faq-answer {
    display: block;
  }
  
  .faq-title {
    font-size: 3em;
    font-weight: bolder;
  }

  .blog-link {
    text-decoration-line: underline;
    color: rgb(14 165 233);
  }

  .blog-list-item {
    list-style-type: decimal;
  }