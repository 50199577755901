.gradient-orange {
    background-image: linear-gradient(to bottom,#f4b69f, #f7e8e3); /* Blend from light orange to white */
  }

.gradient-orange-inscend {
    background-image: linear-gradient(to bottom,#f7e8e3, #f4b69f); /* Blend from light orange to white */
}

.button-container {
  display: inline-flex; /* Align buttons in a row */
  justify-content: center; /* Center the buttons horizontally */
  gap: 20px; /* Creates space between the buttons (if supported) */
}

.button {
  padding: 10px 20px; /* Adjust padding as needed */
  margin-right: 10px; /* Adds space to the right of each button, adjust as needed */
  /* Rest of your button styling */
}

/* Optionally, you can remove the right margin from the last button */
.button:last-child {
  margin-right: 0;
}


/* ChatBot Styles */
.chat-bot {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.messages {
  height: 200px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
}

.user {
  text-align: right;
  background-color: #dcf8c6;
}

.bot {
  text-align: left;
  background-color: #fff;
}

/* Assuming .message is the container for the input field and button */
.message input[type="text"] {
  width: calc(100% - 120px); /* Adjust width as needed */
  padding: 10px;
  margin-right: 10px; /* This gives space between the input and the button */
  border-radius: 5px;
  border: 1px solid #ccc;
}

.message button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #4CAF50;
  color: white;
  margin-left: 10px; /* This pushes the button further away from the input */
}

/* TopTweets Styles */
.top-tweets {
  margin-top: 20px;
}

.tweet {
  border: 1px solid #e1e8ed;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #f5f8fa;
}

.tweet p {
  margin-bottom: 10px;
  color: #14171a;
}

.tweet a {
  color: #1da1f2;
  text-decoration: none;
}

/* PaperSearch Component Styles */
.landing-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the landing container takes minimum full viewport height */
}

.paper-search-section {
  flex-grow: 1; /* Ensures the section takes up remaining space */
  display: flex; /* Enables flexbox for centering */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}

.search-container {
  width: 100%; /* Full width of the section */
  max-width: 600px; /* Maximum width of the search box, adjust as needed */
  margin: 0 auto; /* Centers the search box in the available horizontal space */
}

.input-query {
  padding: 0.5rem 1rem; /* Adequate padding for the input */
  font-size: 1rem; /* Font size similar to the surrounding text */
  border: 2px solid #ddd; /* Subtle border */
  border-radius: 0.375rem; /* Rounded corners (adjust according to your design) */
  margin-right: 0.5rem; /* Space between input and button */
}

.search-button {
  background-color: #007bff; /* Example button color, adjust as needed */
  color: #ffffff; /* Button text color */
  border: none; /* No border for the button */
  padding: 0.5rem 1rem; /* Matching padding with the input field */
  font-size: 1rem; /* Matching font size with the input */
  border-radius: 0.375rem; /* Rounded corners (adjust according to your design) */
  cursor: pointer; /* Cursor changes to pointer to indicate clickability */
  transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.search-button:hover {
  background-color: #0056b3; /* Darker shade on hover, adjust as needed */
}
