/* PaperSearchSummary.css */

.search-container {
  max-width: 800px;
  margin: auto;
  padding: 1rem;
}

.search-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.input-query {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.search-button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}

.results {
  margin-top: 20px;
}

.paper-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-bottom: 1rem;
  padding: 1rem;
}

.paper-title {
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.paper-summary {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
}
